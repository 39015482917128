import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Chip,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import { formatPercentage } from '@jumbo/utils/commonHelper';
import { getDateRange } from '@jumbo/utils/dateHelper';

const ITEM_COLUMN_WIDTH = '80px';
const LABEL_IN_BOTTLES = '(in bottles)';
const LABEL_IN_PUMPS = '(in pumps)';
const align_center = 'center';
const REMAINING_BOTTLES = 'remainingBottles';
const REMAINING_PUMPS = 'remainingPumps';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    '@media print': {
      marginBottom: props => !props.usedMoreThanStock && '48px !important',
    },
  },
  table: {
    minHeight: '90px',

    '@media print': {
      '& th, & td': {
        padding: '4px', // Adjust padding for printing
      },
    },
  },
  headerRow: {
    backgroundColor: theme.palette.primary.main,
  },
  headerCell: {
    minWidth: `${ITEM_COLUMN_WIDTH}`,
  },
  subheaderRow: {
    backgroundColor: theme.palette.primary.main,
  },
  item: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  itemCaption: {
    color: '#757575',
    textAlign: 'left',
    fontStyle: 'italic',
  },
  remainingChip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  infoContainer: {
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  displayCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    height: '50px',
  },
  usedMoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '100%',
  },
  usedMoreTitle: {
    color: '#757575',
    textAlign: 'left',
    marginTop: '16px',
  },
  usedMoreHaveStock: {
    color: '#43a047',
    textAlign: 'left',
    marginTop: '8px',
    marginLeft: '16px',
  },
  usedMoreThanStock: {
    color: theme.palette.error.main,
    textAlign: 'left',
    marginTop: '8px',
    marginLeft: '16px',
  },
}));

const StockTakeInfo = ({ classes, stockTakeDate, stockTakeDetails, loading }) => {
  const usedMorethanStock =
    stockTakeDetails.stockTake &&
    stockTakeDetails.stockTake.length > 0 &&
    stockTakeDetails.stockTake.some(item => item.remainingStockBottle < 0 && item.remainingStockInPumps < 0);

  const infoClasses = useStyles({ usedMorethanStock });

  const headerColumns = [
    { id: 'item', label: 'Item', colSpan: 2, rowSpan: 2 },
    { id: 'stockTake', label: 'Stock Take (existing SOH)', colSpan: 2 },
    { id: 'purchased', label: 'Purchased', colSpan: 2 },
    { id: 'total', label: 'Total', colSpan: 2 },
    { id: 'used', label: 'Used', colSpan: 2 },
    { id: 'remaining', label: 'Remaining', colSpan: 2 },
  ];

  const subHeaderColumns = [
    { id: 'stockTakeBottles', label: `${LABEL_IN_BOTTLES}`, parentId: 'stockTake', align: align_center, borderRight: false },
    { id: 'stockTakePumps', label: `${LABEL_IN_PUMPS}`, parentId: 'stockTake', align: align_center, borderRight: true },
    { id: 'purchasedBottles', label: `${LABEL_IN_BOTTLES}`, parentId: 'purchased', align: align_center, borderRight: false },
    { id: 'purchasedPumps', label: `${LABEL_IN_PUMPS}`, parentId: 'purchased', align: align_center, borderRight: true },
    { id: 'totalBottles', label: `${LABEL_IN_BOTTLES}`, parentId: 'total', align: align_center, borderRight: false },
    { id: 'totalPumps', label: `${LABEL_IN_PUMPS}`, parentId: 'total', align: align_center, borderRight: true },
    { id: 'usedBottles', label: `${LABEL_IN_BOTTLES}`, parentId: 'used', align: align_center, borderRight: false },
    { id: 'usedPumps', label: `${LABEL_IN_PUMPS}`, parentId: 'used', align: align_center, borderRight: true },
    {
      id: `${REMAINING_BOTTLES}`,
      label: `${LABEL_IN_BOTTLES}`,
      parentId: 'remaining',
      align: align_center,
      borderRight: false,
    },
    { id: `${REMAINING_PUMPS}`, label: `${LABEL_IN_PUMPS}`, parentId: 'remaining', align: align_center, borderRight: true },
  ];

  // Cell content mapping
  const getCellContent = (item, columnId) => {
    const mapping = {
      stockTakeBottles: formatPercentage(item.stockTakeBottle, 4),
      stockTakePumps: item.stockTakeInPumps,
      purchasedBottles: formatPercentage(item.purchasedBottle, 4),
      purchasedPumps: item.purchasedInPumps,
      totalBottles: formatPercentage(item.totalStocksBottle, 4),
      totalPumps: item.totalStocksInPumps,
      usedBottles: item.stockUsagesBottle,
      usedPumps: item.stockUsagesInPumps,
      remainingBottles: item.remainingStockBottle,
      remainingPumps: item.remainingStockInPumps,
    };
    return mapping[columnId];
  };

  const getHeaderBackgroundColor = id => {
    switch (id) {
      case 'stockTake':
        return '#1565c0';

      case 'purchased':
        return '#1e88e5';

      case 'total':
        return '#303f9f';

      case 'used':
        return '#3f51b5';

      case 'remaining':
        return '#311b92';

      default:
        return '';
    }
  };

  const renderDetail = (label, value, variant = 'h6', type = '') => (
    <Box className={infoClasses.infoContainer}>
      <Typography variant="body2" component="span">
        {label}:
      </Typography>{' '}
      {value && (
        <Typography variant={variant} component="span">
          {type === 'date' ? getDateRange(value) : value || 'N/A'}
        </Typography>
      )}
    </Box>
  );

  return (
    <div className={clsx(classes.root, infoClasses.outerContainer)}>
      <>
        {renderDetail('Date', stockTakeDate ? moment(stockTakeDate).format('MMMM D, YYYY h:mm A') : '')}
        {renderDetail('Calculated Date Range', stockTakeDetails?.dateRange, 'h6', 'date')}
        {renderDetail('Customer Name', stockTakeDetails.franchiseName)}
        <Typography variant="h3" className={infoClasses.sectionTitle}>
          Stock Take Details
        </Typography>
      </>
      <Table className={clsx(infoClasses.table, classes.borderLeft, classes.borderTop)}>
        <TableHead>
          <TableRow className={infoClasses.headerRow}>
            {headerColumns.map(column => (
              <TableCell
                key={column.id}
                align="center"
                className={clsx(classes.borderRight, classes.header, infoClasses.headerCell)}
                style={{
                  backgroundColor: getHeaderBackgroundColor(column.id),
                }}
                colSpan={column.colSpan}
                rowSpan={column.rowSpan || 1}>
                {column.id === 'remaining' && usedMorethanStock ? `${column.label} **` : column.label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow className={infoClasses.subheaderRow}>
            {subHeaderColumns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                className={clsx(classes.borderRight, classes.header)}
                style={{
                  minWidth: column.id === 'item' ? `${ITEM_COLUMN_WIDTH}` : 80,
                  backgroundColor: getHeaderBackgroundColor(column.parentId),
                  '@media print': {
                    minWidth: '35',
                  },
                }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stockTakeDetails.stockTake && stockTakeDetails.stockTake.length > 0 ? (
            stockTakeDetails.stockTake.map(item => (
              <TableRow key={item.inventoryID} className={classes.tableRowRoot}>
                <TableCell align="left" colSpan={2} className={infoClasses.item}>
                  <Box>{item.inventoryItemName}</Box>
                  <Typography variant="caption" display="block" className={infoClasses.itemCaption}>
                    {`(1 bottle = ${item?.pumpPerBottle} pumps)`}
                  </Typography>
                </TableCell>
                {subHeaderColumns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      borderRight: column.borderRight ? '1px solid rgba(224, 224, 224, 1)' : '',
                      color:
                        (column.id === `${REMAINING_BOTTLES}` || column.id === `${REMAINING_PUMPS}`) && usedMorethanStock
                          ? '#43a047'
                          : 'default',
                    }}>
                    {column.id === `${REMAINING_BOTTLES}` || column.id === `${REMAINING_PUMPS}` ? (
                      <Box className={infoClasses.displayCenter}>
                        {parseInt(
                          column.id === `${REMAINING_BOTTLES}`
                            ? formatPercentage(item.remainingStockBottle, 4)
                            : item.remainingStockInPumps,
                        ) < 0 ? (
                          <Chip
                            label={
                              column.id === `${REMAINING_BOTTLES}`
                                ? formatPercentage(item.remainingStockBottle, 4)
                                : item.remainingStockInPumps
                            }
                            className={infoClasses.remainingChip}
                          />
                        ) : column.id === `${REMAINING_BOTTLES}` ? (
                          formatPercentage(item.remainingStockBottle, 4)
                        ) : (
                          item.remainingStockInPumps
                        )}
                      </Box>
                    ) : (
                      getCellContent(item, column.id)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12}>
                <Box className={infoClasses.loading}>
                  {loading ? <CircularProgress size={30} /> : <Typography variant="h4">No Stock Take found</Typography>}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {usedMorethanStock ? (
        <Box className={infoClasses.usedMoreContainer}>
          <Box>
            <Typography variant="body2" display="block" className={infoClasses.usedMoreTitle}>
              ** Remaining (in bottles) and (in pumps)
            </Typography>
            <Typography variant="body2" display="block" className={infoClasses.usedMoreHaveStock}>
              - Green value: Have enough stock
            </Typography>
            <Typography variant="body2" display="block" className={infoClasses.usedMoreThanStock}>
              - Red value: Have used more stock than they have
            </Typography>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </div>
  );
};

export default StockTakeInfo;
