import axios from 'axios';
import { config } from './global';

const token = JSON.parse(localStorage.getItem('token'));

export const httpClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    Authorization: 'Bearer ' + token,
  },
});
