import React, { useEffect, useState } from 'react';
import { Button, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Close from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { httpClient } from 'appUtility/Api';

const Filter = ({
  classes,
  filter,
  handleFilter,
  filterData,
  handleStartDate,
  handleEndDate,
  setFilter,
  submittedData,
  handleRemove,
  handleChange,
}) => {
  const [state, setState] = useState([]);

  const getStateSuburb = () => {
    httpClient.get('/state/suburb').then(({ data }) => {
      if (data) {
        setState(data.data);
      }
    });
  };

  useEffect(() => {
    getStateSuburb();
  }, []);

  const getLastLoginDays = day => {
    switch (day) {
      case 7:
        return '7 days';

      case 14:
        return '14 days';

      case 30:
        return '30 days';

      default:
        return '';
    }
  };
  return (
    <>
      {filter && (
        <Collapse in={filter} className={classes?.filterBox}>
          <form onSubmit={handleFilter}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={6}>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  label="Start Date"
                  name="startDate"
                  value={filterData?.startDate ? filterData?.startDate : null}
                  onChange={handleStartDate}
                  className={classes?.dateField}
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  label="End Date"
                  name="endDate"
                  value={filterData?.endDate ? filterData?.endDate : null}
                  onChange={handleEndDate}
                  format="YYYY-MM-DD"
                  className={classes?.dateField}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className={classes.inputLabel}>Last Login</InputLabel>
                <FormControl variant="outlined" className={classes.text}>
                  <Select
                    name="lastLoginDays"
                    // label="Active Members"
                    value={filterData.lastLoginDays}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value=" ">&nbsp;</MenuItem>
                    <MenuItem value="7">7 days</MenuItem>
                    <MenuItem value="14">14 days</MenuItem>
                    <MenuItem value="30">30 days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className={classes.inputLabel}>State</InputLabel>
                <FormControl variant="outlined" className={classes.text}>
                  <Select
                    name="state"
                    // label="Active Members"
                    value={filterData.state}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem style={{ fontStyle: 'italic' }} disabled>
                      Select State
                    </MenuItem>
                    {state && state.map(item => <MenuItem value={item.stateID}>{item.stateName}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className={classes.inputLabel}>Logged In</InputLabel>
                <FormControl variant="outlined" className={classes.text}>
                  <Select
                    name="loggedIn"
                    // label="Active Members"
                    value={filterData.loggedIn}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value=" ">&nbsp;</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className={classes.inputLabel}>Active</InputLabel>
                <FormControl variant="outlined" className={classes.text}>
                  <Select
                    name="active"
                    // label="Active Members"
                    value={filterData.active}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value=" ">&nbsp;</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <InputLabel className={classes.inputLabel}>Search</InputLabel>
                <TextField
                  // label="Search"
                  variant="outlined"
                  name="search"
                  value={filterData.search}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={12}>
                <Box textAlign="right">
                  <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                    Filter Data
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Collapse>
      )}
      {submittedData?.startDate ||
      submittedData?.endDate ||
      submittedData?.lastLoginDays ||
      submittedData?.state ||
      submittedData?.loggedIn ||
      submittedData?.active ||
      submittedData?.search ? (
        <Box className={classes?.productView}>
          <p>Filtered: </p>
          {submittedData?.startDate && (
            <p>
              Start Date: <span>{moment(submittedData?.startDate).format('Do MMMM, YYYY')} </span>
              <Close fontSize="small" onClick={() => handleRemove('startDate')} />
            </p>
          )}
          {submittedData?.endDate && (
            <p>
              End Date:<span> {moment(submittedData?.endDate).format('Do MMMM, YYYY')} </span>
              <Close fontSize="small" onClick={() => handleRemove('endDate')} />
            </p>
          )}
          {submittedData.lastLoginDays && (
            <p>
              Last Login: <span>{getLastLoginDays(parseInt(submittedData.lastLoginDays))} </span>
              <Close fontSize="small" onClick={() => handleRemove('lastLoginDays')} />
            </p>
          )}
          {submittedData.state && (
            <p>
              State:
              <span>
                {state &&
                  state.map(st => {
                    if (st.stateID === filterData.state) return st.stateName;
                  })}
              </span>
              <Close fontSize="small" onClick={() => handleRemove('state')} />
            </p>
          )}
          {submittedData.loggedIn && (
            <p>
              Logged In: <span>{submittedData.loggedIn === 1 ? 'Yes' : 'No'} </span>
              <Close fontSize="small" onClick={() => handleRemove('loggedIn')} />
            </p>
          )}
          {submittedData.active && (
            <p>
              Active: <span>{submittedData.active === 1 ? 'Yes' : 'No'} </span>
              <Close fontSize="small" onClick={() => handleRemove('active')} />
            </p>
          )}
          {submittedData.search && (
            <p>
              Search: <span>{submittedData.search} </span>
              <Close fontSize="small" onClick={() => handleRemove('search')} />
            </p>
          )}
        </Box>
      ) : (
        <Box className={classes?.productView} />
      )}
    </>
  );
};

export default Filter;
