const loginData = JSON.parse(localStorage.getItem('login'));

const ENVIRONMENTS = {
  LOCAL: 'local',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return ENVIRONMENTS.LOCAL;
    case 'backadmin.franchise.care':
      return ENVIRONMENTS.STAGING;
    default:
      return ENVIRONMENTS.PRODUCTION;
  }
};

const getBaseUrl = env => {
  if (env === ENVIRONMENTS.STAGING) {
    if (loginData && loginData.user.SuperAdmin === '0') {
      return 'backadmin.franchise.care/php/api/member/app/1.0';
    } else {
      return 'backadmin.franchise.care/php/api/app/1.0/';
    }
  } else if (env === ENVIRONMENTS.PRODUCTION) {
    if (loginData && loginData.user.SuperAdmin === '0') {
      return 'admin.franchise.care/php/api/member/app/1.0/';
    } else {
      return 'admin.franchise.care/php/api/app/1.0/';
    }
  } else {
    if (loginData && loginData.user.SuperAdmin === '0') {
      return 'backadmin.franchise.care/php/api/member/app/1.0';
    } else {
      return 'backadmin.franchise.care/php/api/app/1.0/';
    }
  }
};

const createConfig = env => {
  const protocol = window.location.protocol;
  const baseUrl = getBaseUrl(env);

  return {
    apiUrl: `${protocol}//${baseUrl}/`,
    version: '1.0',
  };
};

const environment = getEnvironment();
export const config = createConfig(environment);
