import React, { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  makeStyles,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Snackbar,
  Button,
  Tooltip,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import StockTakeInfo from './StockTakeInfo';
import BookingOverview from './BookingOverview';
import clsx from 'clsx';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    '@media print': {
      padding: '24px 16px',
    },
  },
  root: {
    margin: '20px auto',
    padding: theme.spacing(2),
  },
  date: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  header: {
    color: theme.palette.common.white,
  },
  borderLeft: {
    '@media print': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  borderTop: {
    '@media print': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  borderRight: {
    borderRight: `1px solid #fff`,
    '@media print': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  hideInPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StockTakeDetailsDialog = props => {
  const { memberID, stockTakeID, stockTakeDate } = props;

  const classes = useStyles();

  const [stockTakeDetails, setStockTakeDetails] = useState([]);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const theme = useTheme();
  const contentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef,
  });

  const getStockTakeDetails = () => {
    setLoading(true);

    httpClient.get(`member/${memberID}/stocktake/${stockTakeID}`).then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setStockTakeDetails(data.data);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(data.message);
        setMessageState('error');

        setTimeout(() => {
          props.onClose(); // close the modal
        }, 2000);

        return;
      }
    });
  };

  useEffect(() => {
    getStockTakeDetails();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog open={true} onClose={() => props.onClose()} maxWidth="xl">
        <Box className={classes.dialogTitle}>
          <DialogTitle></DialogTitle>
          <IconButton onClick={props.onClose} className={clsx(classes.closeBtn, classes.hideInPrint)}>
            <Close
              style={{
                color: theme.palette.error.main,
              }}
            />
          </IconButton>
        </Box>
        <DialogContent className={classes.content}>
          <Box ref={contentRef} className={classes.contentContainer}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <h2>Stock Take date and other details</h2>
              <Tooltip title="Print">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={clsx(classes.button, classes.hideInPrint)}
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}>
                  Print
                </Button>
              </Tooltip>
            </Box>
            <StockTakeInfo
              classes={classes}
              stockTakeDate={stockTakeDate}
              stockTakeDetails={stockTakeDetails}
              loading={loading}
            />
            <BookingOverview classes={classes} stockTakeDetails={stockTakeDetails} loading={loading} />
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StockTakeDetailsDialog;
