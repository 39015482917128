import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import ViewDialog from '../ViewDialog';
import TableComponent from './../TableComponent';
import moment from 'moment';

const columns = [
  { id: 'loginDate', label: 'Last Login' },
  { id: 'memberName', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'no_of_login', label: 'No. of Logins' },
  { id: 'loginIP', label: 'IP Address' },
  { id: 'isMobile', label: 'Mobile Login' },
  { id: 'os', label: 'OS' },
  { id: 'Actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const LoginLog = () => {
  const [loginData, setLoginData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewID, setViewID] = useState('');
  const [viewName, setViewName] = useState('');

  var storedLoginReportFilter = JSON.parse(localStorage.getItem('login_report_filter'));

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    startDate:
      storedLoginReportFilter !== null
        ? storedLoginReportFilter.startDate
        : moment()
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
    endDate: storedLoginReportFilter !== null ? storedLoginReportFilter.endDate : moment().format('YYYY-MM-DD'),
    currStartDate:
      storedLoginReportFilter !== null
        ? storedLoginReportFilter.currStartDate
        : moment()
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
    currEndDate: storedLoginReportFilter !== null ? storedLoginReportFilter.currEndDate : moment().format('YYYY-MM-DD'),
    lastLoginDays: '',
    state: '',
    loggedIn: '',
    active: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    startDate:
      storedLoginReportFilter !== null
        ? storedLoginReportFilter.startDate
        : moment()
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
    endDate: storedLoginReportFilter !== null ? storedLoginReportFilter.endDate : moment().format('YYYY-MM-DD'),
    currStartDate:
      storedLoginReportFilter !== null
        ? storedLoginReportFilter.currStartDate
        : moment()
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
    currEndDate: storedLoginReportFilter !== null ? storedLoginReportFilter.currEndDate : moment().format('YYYY-MM-DD'),
    lastLoginDays: '',
    state: '',
    loggedIn: '',
    active: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.startDate === '' &&
      filterData.endDate === '' &&
      filterData.lastLoginDays === '' &&
      filterData.state === '' &&
      filterData.loggedIn === '' &&
      filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }

    if (filterData.startDate === ' ') filterData.startDate = '';
    if (filterData.endDate === ' ') filterData.endDate = '';
    if (filterData.lastLoginDays === ' ') filterData.lastLoginDays = '';
    if (filterData.state === ' ') filterData.state = '';
    if (filterData.loggedIn === ' ') filterData.loggedIn = '';
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let storedLoginReportFilter = JSON.parse(localStorage.getItem('login_report_filter'));
    storedLoginReportFilter !== null && setFilterData(storedLoginReportFilter);

    const isEmptyStoredDate =
      storedLoginReportFilter?.startDate === '' &&
      storedLoginReportFilter?.currStartDate === '' &&
      storedLoginReportFilter?.endDate === '' &&
      storedLoginReportFilter?.currEndDate === '' &&
      storedLoginReportFilter?.lastLoginDays === '' &&
      storedLoginReportFilter?.state === '' &&
      storedLoginReportFilter?.loggedIn === '' &&
      storedLoginReportFilter?.active === '';

    if (storedLoginReportFilter === null || isEmptyStoredDate) {
      getAllLogins();
    }
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      lastLoginDays: filterData.lastLoginDays,
      state: filterData.state,
      loggedIn: filterData.loggedIn,
      active: filterData.active,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('login_report_filter', JSON.stringify(filterData));
    if (
      filterData.startDate ||
      filterData.endDate ||
      filterData.lastLoginDays ||
      filterData.state ||
      filterData.loggedIn ||
      filterData.active ||
      filterData.search
    ) {
      httpClient
        .get(
          `member/report/login-log/filter?start_date=${filterData.currStartDate}&end_date=${filterData.currEndDate}&recordDays=${filterData.lastLoginDays}&state=${filterData.state}&loggedInUser=${filterData.loggedIn}&active=${filterData.active}&q=${filterData.search}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setLoginData(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllLogins();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleStartDate = e => {
    setFilterData({
      ...filterData,
      startDate: moment(e).format('YYYY-MM-DD'),
      currStartDate: moment(e).format('YYYY-MM-DD'),
      removed: false,
    });
  };

  const handleEndDate = e => {
    setFilterData({
      ...filterData,
      endDate: moment(e).format('YYYY-MM-DD'),
      currEndDate: moment(e).format('YYYY-MM-DD'),
      removed: false,
    });
  };

  const handleRemove = data => {
    if (data === 'startDate') {
      filterData.startDate = '';
      filterData.currStartDate = '';
      submittedData.startDate = '';
      submittedData.currStartDate = '';
    }
    if (data === 'endDate') {
      filterData.endDate = '';
      filterData.currEndDate = '';
      submittedData.endDate = '';
      submittedData.currEndDate = '';
    }

    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpClient
          .get(
            `member/report/login-log/filter?start_date=${filterData.currStartDate}&end_date=${
              filterData.currEndDate
            }&recordDays=${filterData.lastLoginDays}&state=${filterData.state}&loggedInUser=${filterData.loggedIn}&active=${
              filterData.active
            }&q=${filterData.search}&sort_by=${column.id}&direction=${
              !direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoginData(data.data);
            }
          })
      : httpClient
          .get(
            `member/report/login-log/filter?sort_by=${column.id}&direction=${
              !direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}`,
          )
          .then(({ data }) => {
            if (data.success) {
              setLoginData(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `member/report/login-log/filter?start_date=${filterData.currStartDate}&end_date=${filterData.currEndDate}&recordDays=${filterData.lastLoginDays}&state=${filterData.state}&loggedInUser=${filterData.loggedIn}&active=${filterData.active}&q=${filterData.search}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoginData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `member/report/login-log/filter?sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoginData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `member/report/login-log/filter?start_date=${filterData.currStartDate}&end_date=${
              filterData.currEndDate
            }&recordDays=${filterData.lastLoginDays}&state=${filterData.state}&loggedInUser=${filterData.loggedIn}&active=${
              filterData.active
            }&q=${filterData.search}&sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${+event.target.value}&page=${page}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setLoginData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `member/report/login-log/filter?sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${+event.target.value}&page=${page}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setLoginData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const getAllLogins = () => {
    setLoading(true);
    httpClient.get(`member/report/login-log?&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
      if (data) {
        setLoginData(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  const handleView = val => {
    setViewID(val.id);
    setViewName(val.name);
    setDialogOpen(true);
  };

  const sendTo = child => {
    child === false && setDialogOpen(false);
  };

  return (
    <PageContainer heading="Login Log">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={loginData}
              title="Login Log"
              handleView={handleView}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      {dialogOpen && <ViewDialog sendTo={sendTo} viewID={viewID} title={viewName} />}
    </PageContainer>
  );
};

export default LoginLog;
