import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { getDateRange } from '@jumbo/utils/dateHelper';

const useStyles = makeStyles(theme => ({
  table: {
    minHeight: '100px',
    maxWidth: '1024px',
  },
  headerRow: {
    backgroundColor: theme.palette.primary.main,
  },
  itemCaption: {
    color: '#757575',
    textAlign: 'left',
    fontStyle: 'italic',
  },
  infoContainer: {
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    '@media print': {
      marginTop: theme.spacing(12),
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    height: '50px',
  },
}));

const BookingOverview = ({ classes, stockTakeDetails, loading }) => {
  const bookingClasses = useStyles();

  // Define columns configuration
  const columns = [
    {
      id: 'service',
      label: 'Service',
      align: 'left',
    },
    {
      id: 'item',
      label: 'Item',
      align: 'left',
    },
    {
      id: 'serviceUsed',
      label: 'No. of Service Used',
      align: 'center',
    },
    {
      id: 'pumpsUsed',
      label: 'Total Pumps used',
      align: 'center',
    },
  ];

  // Get cell content based on column id
  const getCellContent = (booking, stock, columnId, isFirstRow) => {
    const mapping = {
      service: isFirstRow ? booking.serviceName || 'Bulk' : null,
      item: stock.inventoryName,
      serviceUsed: stock.serviceUsed,
      pumpsUsed: stock.numOfPumps,
    };
    return mapping[columnId];
  };

  const renderDetail = (label, value, variant = 'h6', type = '') => (
    <Box className={bookingClasses.infoContainer}>
      <Typography variant="body2" component="span">
        {label}:
      </Typography>{' '}
      {value && (
        <Typography variant={variant} component="span">
          {type === 'date' ? getDateRange(value) : value || 'N/A'}
        </Typography>
      )}
    </Box>
  );

  return (
    <div className={classes.root}>
      <>
        <Typography variant="h3" className={bookingClasses.sectionTitle}>
          Booking Overview
        </Typography>
        {renderDetail('Calculated Date Range', stockTakeDetails?.dateRange, 'h6', 'date')}
        {renderDetail('Total No of Completed Bookings', stockTakeDetails?.numOfBookings ?? 0)}
      </>

      <Table className={bookingClasses.table}>
        <TableHead>
          <TableRow className={bookingClasses.headerRow}>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                className={clsx(classes.header, column.id !== 'pumpsUsed' && classes.borderRight)}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stockTakeDetails?.usedByServices?.length > 0 ? (
            stockTakeDetails.usedByServices.map(booking =>
              booking.stockDetails.map((stock, index) => (
                <TableRow key={`${booking.appID}-${booking.companyID}-${stock.inventoryID}`}>
                  {columns.map(column => {
                    const isFirstRow = index === 0;
                    const content = getCellContent(booking, stock, column.id, isFirstRow);

                    // Skip cell rendering for service column if not first row
                    if (column.id === 'service' && !isFirstRow) {
                      return null;
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={clsx(column.id !== 'pumpsUsed' && classes.borderRight)}
                        {...(column.id === 'service' && { rowSpan: booking.stockDetails.length })}>
                        <Box>{content}</Box>
                        {column.id === 'item' ? (
                          <Typography variant="caption" display="block" className={bookingClasses.itemCaption}>
                            {stock.pumpPerService && stock.pumpPerService !== '-'
                              ? `(${stock.pumpPerService} ${
                                  parseInt(stock.pumpPerService) === 1 ? 'pump' : 'pumps'
                                } per service)`
                              : ''}
                          </Typography>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )),
            )
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box className={bookingClasses.loading}>
                  {loading ? <CircularProgress size={30} /> : <Typography variant="h4">No Booking found</Typography>}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default BookingOverview;
